import { mq, styled, useLanguageDirection } from '@asicsdigital/oneasics-blocks';

const StyledPage = styled.div(
  mq({
    display: 'flex',
    flexDirection: ['column', null, null, 'row'],
    minHeight: '100%',
  }),
);

/**
 * The container for all elements on a page.
 */
export const Page = ({ children }) => {
  const { direction } = useLanguageDirection();

  return (
    <StyledPage dir={direction}>{children}</StyledPage>
  );
};
