import React from 'react';

import { createRoot } from 'react-dom/client';

import App from './components/app/App';
import './easter';
import './i18n';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './styles.css';

// DATADOG INIT CODE COMMENTED OUT FOR NOW
// if (ENVIRONMENT_NAME === 'staging' || ENVIRONMENT_NAME === 'prod') {
//   datadogRum.init({
//     applicationId: 'cd9c9ec6-4e2e-4cac-a357-abf7bf0afefa',
//     clientToken: 'pub95cb55ef369e7c4b10049dda4bfb6a85',
//     site: 'datadoghq.com',
//     service: 'oneasics-instore',
//     env: ENVIRONMENT_NAME,
//     // Specify a version number to identify the deployed version of your application in Datadog
//     version: process.env.REACT_APP_VERSION,
//     sessionSampleRate: 100,
//     sessionReplaySampleRate: 20,
//     trackUserInteractions: true,
//     trackResources: true,
//     trackLongTasks: true,
//     defaultPrivacyLevel: 'mask-user-input',
//   });
//   datadogRum.startSessionReplayRecording();
// }

const domNode = document.getElementById('root');
const root = createRoot(domNode);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
