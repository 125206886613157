import { lazy, useEffect } from 'react';

import { AnalyticsApi, defaultTheme, GatedRoute, GlobalStyles, LanguageDirectionProvider, styled, ThemeProvider, useAuthCheck, useModal, useOneTrust } from '@asicsdigital/oneasics-blocks';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useTracking } from 'react-tracking';

import { useAddToHomeScreen } from '../../hooks/use-add-to-home-screen';
import { oauthApi, storesApi, usersApi } from '../../services/api';
import { setQueryParams, setRegionStore, setStores } from '../../store/routerSlice';
import { getAccessToken, resetUserData } from '../../store/userSlice';
import { ENVIRONMENT_NAME, ONETRUST_DATA_DOMAIN_SCRIPT, REDIRECT_URI as redirectUri } from '../../utilities/constants';
import DebuggerModal from '../modal/DebuggerModal';
import NavBar from '../nav-bar/NavBar';
import { Page } from '../page/Page';



const Account = lazy(() => import('../../views/account/Account'));
const Benefit = lazy(() => import('../../views/benefit/Benefit'));
const Login = lazy(() => import('../../views/login/Login'));
const MemberBenefits = lazy(() => import('../../views/member-benefits/MemberBenefits'));
const MemberId = lazy(() => import('../../views/member-id/MemberId'));
const RegionSelect = lazy(() => import('../../views/region-select/RegionSelect'));
const Shop = lazy(() => import('../../views/shop/Shop'));
const Welcome = lazy(() => import('../../views/welcome/Welcome'));

const DebuggerModalButton = styled.button({
  position: 'absolute',
  right: '10px',
  top: '5px',
});

const PageBody = styled.div({
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
  overflowX: 'clip',
});

/**
 * The body of the app. Holds all base views / routing / tracking logic.
 */
const AppBody = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { router, user } = useSelector((state) => state);
  const { i18n } = useTranslation();
  const { Track, trackEvent } = useTracking({
    app_id: 'oneasics-member',
    asics_id: user?.user?.asicsId,
    locale: i18n.language,
    region: router?.store?.regionId,
    session: router?.session,
    source_id: 'INSTORE',
    store_id: router?.store?.name,
    store_type: router?.store?.storeType,
    store_sapid: router?.store?.id,
  }, {
    dispatch: (data) => AnalyticsApi.logAnalytics(data),
  });
  useAddToHomeScreen({ trackEvent });

  useAuthCheck({ oauthApi, resetUserData, usersApi });

  const urlSearchParams = new URLSearchParams(location.search);

  /**
   * If locale was passed in the url, change the locale to match.
   */
  const updateLocaleFromUrl = () => {
    const newLocale = urlSearchParams.get('locale');
    if (newLocale) {
      i18n.changeLocale(newLocale.replace('!new', '')); // remove !new from shortlinks
    }
  };

  useOneTrust({
    dataDomainScript: ONETRUST_DATA_DOMAIN_SCRIPT,
    locale: i18n.language,
    view: location.pathname,
    webview: false,
  });

  // Only set store once the store list has been loaded
  useEffect(() => {
    if (router.stores?.length > 0) {
      dispatch(setRegionStore({
        regionId: router.queryParamsRegion,
        storeId: router.queryParamsStore,
      }), [router.store, router.stores]);
    }
  }, [dispatch, router.queryParamsRegion, router.queryParamsStore, router.store, router.stores]);

  /**
   * TODO: remove this logic eventually.
   * This logic is to keep the url search data in sync with the actual page data.
   * This is logic we used in the old instore app and is relied on heavily for our browser tests.
   * We could get rid of this but we'd have to redo the tests.
   */
  const replaceUrlData = () => {
    const newURL = `${window.location.origin}${window.location.pathname}` + // eslint-disable-line prefer-template
      `?locale=${i18n.language}` +
      `&region=${router.store?.regionId}` +
      `&store=${router.store?.id}`
      .replace('!new', ''); // remove !new from shortlinks
    window.history.replaceState(null, 'OneASICS Instore', newURL);
  };
  useEffect(() => { replaceUrlData(); }, [i18n.language, router.store, window.location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(storesApi.endpoints.getStores.initiate('oneasics_instore')).then((response) => {
      dispatch(setStores(response.data));
    });

    updateLocaleFromUrl();

    dispatch(setQueryParams({
      queryParamsRegion: urlSearchParams.get('region'),
      queryParamsStore: urlSearchParams.get('store'),
    }));

    const code = urlSearchParams.get('code');
    if (code) {
      dispatch(getAccessToken({ code, codeVerifier: localStorage.codeVerifier, redirectUri })).then(() => replaceUrlData());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { openModal: openDebuggerModal, RenderModal } = useModal();

  const gatedRouteConditional = user.isLoggedIn && Boolean(router.store.regionId);

  return (
    <Track>
      <GlobalStyles />
      <ThemeProvider theme={defaultTheme}>
        <LanguageDirectionProvider>
          <Page>
            <PageBody>
              <Switch>
                {/* Auth routes */}
                <GatedRoute component={Account} conditional={gatedRouteConditional} path="/account" />
                <GatedRoute component={Benefit} conditional={gatedRouteConditional} path="/member-benefits/:benefitId" />
                <GatedRoute component={MemberBenefits} conditional={gatedRouteConditional} path="/member-benefits" />
                <GatedRoute component={MemberId} conditional={gatedRouteConditional} path="/member-id" />
                <GatedRoute component={Shop} conditional={gatedRouteConditional} path="/shop" />

                {/* No auth routes */}
                <Route component={Welcome} path="/welcome" redirect="/region-select" />
                <Route component={Login} path="/login" />
                <Route component={RegionSelect} path="/region-select" />
                <Redirect from="/" to="/region-select" />
              </Switch>
              {user.isLoggedIn && <NavBar />}
            </PageBody>
          </Page>
        </LanguageDirectionProvider>
        {
          (ENVIRONMENT_NAME !== 'prod' && ENVIRONMENT_NAME !== 'staging') && (
            <DebuggerModalButton
              onClick={openDebuggerModal}
            >
              Toggle Debugger
            </DebuggerModalButton>
          )
        }
        <RenderModal><DebuggerModal /></RenderModal>
      </ThemeProvider>
    </Track>
  );
};

AppBody.propTypes = {};

AppBody.defaultProps = {};

export default AppBody;
