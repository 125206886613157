import { useEffect } from 'react';

let prompt; // eslint-disable-line import/no-mutable-exports

export const promptToInstall = () => {
  if (prompt) {
    return prompt.prompt();
  }
  return Promise.reject(
    new Error(
      'Tried installing before browser sent "beforeinstallprompt" event',
    ),
  );
};

/**
 * A hook that adds A2HS capabilites to any component.
 */
export const useAddToHomeScreen = ({ trackEvent }) => {

  useEffect(() => {
    const onReady = (event) => {
      event.preventDefault();
      prompt = event;
    };

    window.addEventListener('beforeinstallprompt', onReady);

    window.onappinstalled = () => {
      trackEvent({ 'event_name': 'pwa-install' });
    };

    return () => {
      window.removeEventListener('beforeinstallprompt', onReady);
      prompt = undefined;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [];
};

export { prompt };
