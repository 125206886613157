import React, { Suspense } from 'react';

import { Loading } from '@asicsdigital/oneasics-blocks';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { persistor, store } from '../../store/store';
import AppBody from '../app-body/AppBody';

/**
 * The entry point of the app.
 */
const App = () => (
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <Router basename={process.env.PUBLIC_URL}>
          <Suspense fallback={<Loading />}>
            <AppBody />
          </Suspense>
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

App.propTypes = {};

App.defaultProps = {};

export default App;
