import { Button, Link, useLogout } from '@asicsdigital/oneasics-blocks';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useTracking } from 'react-tracking';

import { oauthApi, usersApi } from '../../services/api';
import { addDebuggingDefaultStores, setRegionStore } from '../../store/routerSlice';
import { debugPoints, resetUserData } from '../../store/userSlice';

/**
 * Modal used only in development environemnts to help anyone working on the app see info with any dev tools.
 */
const DebuggerModal = () => {
  const dispatch = useDispatch();
  const {
    router: { session, store },
    user: { isLoggedIn, user },
  } = useSelector((state) => state);
  const { Track } = useTracking(
    { event_type: 'view', page: 'debugger-modal' },
    { dispatchOnMount: true },
  );
  const { i18n } = useTranslation();
  const [handleLogout] = useLogout({ oauthApi, resetUserData, usersApi });

  const setRegionAEG = () => dispatch(setRegionStore({ regionId: 'AEG', storeId: '0000' }));
  const setRegionAJP = () => dispatch(setRegionStore({ regionId: 'AJP', storeId: '00000' }));
  const setRegionANA = () => dispatch(setRegionStore({ regionId: 'ANA', storeId: '000' }));
  const handleAddStores = () => dispatch(addDebuggingDefaultStores());
  const handleDebugPoints = () => dispatch(debugPoints());

  return (
    <Track>
      <Link path="/account">Account</Link>
      <Link path="/member-benefits">Member Benefits</Link>
      <Link path="/member-id">Member Id</Link>
      <Link path="/region-select">Region Select</Link>
      <Link path="/shop">Shop</Link>
      <Link path="/welcome">Welcome</Link>
      <div>
        <div>Locale: {i18n.language}</div>
        <div>Region: {store?.regionId || 'no store selected'}</div>
        <div>Store ID: {store?.id || 'no store selected'}</div>
        <div>Store Name: {store?.name || 'no store name'}</div>
        <div>Store Connector ID: {store?.connectorId || 'no store connector id'}</div>
        <div>Session: {session}</div>
        <div>Is Logged In: {String(isLoggedIn)}</div>
        <div>User: {user?.asicsId || 'no user logged in'}</div>
      </div>
      <Button
        color="white"
        id="debug-add-stores"
        onClick={handleAddStores}
      >
        Add Default Stores
      </Button>
      <Button color="white" id="debug-set-aeg" onClick={setRegionAEG}>Set AEG</Button>
      <Button color="white" id="debug-set-ajp" onClick={setRegionAJP}>Set AJP</Button>
      <Button color="white" id="debug-set-ana" onClick={setRegionANA}>Set ANA</Button>
      <Button color="white" id="debug-add-points" onClick={handleDebugPoints}>Debug Points</Button>
      <Button color="white" id="debug-logout" onClick={handleLogout}>Logout</Button>
    </Track>
  );
};

DebuggerModal.propTypes = {};

DebuggerModal.defaultProps = {};

export default DebuggerModal;
