import { createSlice, nanoid } from '@reduxjs/toolkit';

const regionDefaults = {
  AEG: {
    storeId: '0000',
  },
  AJP: {
    storeId: '00000',
  },
  ANA: {
    storeId: '000',
  },
};

export const routerSlice = createSlice({
  name: 'router',
  initialState: {
    session: nanoid(),
    queryParamsRegion: '',
    queryParamsStore: '',
    store: {},
    stores: [],
    storeTypes: {
      fullPrice: 'Performance Full Price',
      outlet: 'Performance Outlet',
      walking: 'Walking Full Price',
    },
  },
  reducers: {
    addDebuggingDefaultStores: (state) => {
      state.stores = [{
        id: '0000',
        regionId: 'AEG',
      }, {
        id: '00000',
        regionId: 'AJP',
      }, {
        id: '000',
        regionId: 'ANA',
      }];
    },
    setQueryParams: (state, action) => {
      let cleanedStoreParam = action.payload.queryParamsStore;
      if (cleanedStoreParam && cleanedStoreParam.indexOf('store') > -1) {
        [, cleanedStoreParam] = cleanedStoreParam.split('store');
      }
      if (cleanedStoreParam && cleanedStoreParam.indexOf('!new') > -1) {
        [cleanedStoreParam] = cleanedStoreParam.split('!new');
      }

      state.queryParamsRegion = action.payload.queryParamsRegion ?? state.queryParamsRegion;
      state.queryParamsStore = cleanedStoreParam ?? state.queryParamsStore;
    },
    setRegionStore: (state, action) => {
      if (action.payload.storeId) {
        action.payload.storeId = action.payload.storeId.replace(/\D+/g, '');
        const newStore = state.stores.find((store) => store.id === action.payload.storeId);

        if (newStore) {
          state.store = newStore;
          state.queryParamsRegion = newStore.regionId;
          state.queryParamsStore = newStore.id;
        }
      } else if (action.payload.regionId) {
        state.store = state.stores.find((store) => store.id === regionDefaults[action.payload.regionId].storeId);
        state.queryParamsRegion = state.store.regionId;
        state.queryParamsStore = state.store.id;
      }
    },
    setStores: (state, action) => {
      state.stores = action.payload || [];
    },
  },
});

export const {
  addDebuggingDefaultStores,
  setQueryParams,
  setRegionStore,
  setStores,
} = routerSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectCount = state => state.counter.value;

export default routerSlice.reducer;
