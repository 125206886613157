import { combineReducers, configureStore } from '@reduxjs/toolkit';
import localForage from 'localforage';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';

import routerReducer from './routerSlice';
import userReducer from './userSlice';
import { oauthApi, storesApi, usersApi } from '../services/api';

const combinedReducers = combineReducers({
  router: routerReducer,
  [oauthApi.reducerPath]: oauthApi.reducer,
  [storesApi.reducerPath]: storesApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  user: userReducer,
});

const persistConfig = {
  key: 'root',
  migrate: (state) => Promise.resolve(state),
  storage: localForage,
  version: 0,
};

const persistedReducer = persistReducer(persistConfig, combinedReducers);

export const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: { ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER] },
  }).concat(oauthApi.middleware)
    .concat(storesApi.middleware)
    .concat(usersApi.middleware),
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
