import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import localeFallbacks from './locales/fallbacks.json';
import locales from './locales/locales.json';

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/translations/{{lng}}/{{ns}}.json`,
    },

    cleanCode: true, // language will be lowercased EN --> en while leaving full locales like en-US

    fallbackLng: localeFallbacks,

    debug: false,

    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        switch (format) {
          case 'intlDate':
            return new Intl.DateTimeFormat(
              lng,
              { year: 'numeric', month: 'long', day: 'numeric' },
            ).format(value);
          case 'monthYear':
            return Intl.DateTimeFormat(
              lng,
              { month: 'long', year: 'numeric' },
            ).format(value);
          case 'yearMonthDate':
            return Intl.DateTimeFormat(
              lng,
              { year: 'numeric', month: 'numeric', day: 'numeric' },
            ).format(value);
          default:
            break;
        }

        return value;
      },
    },

    ns: ['AEG', 'AJP', 'ANA'],
    fallbackNS: 'AEG',

    parseMissingKeyHandler: () => null,
  });

i18n.locales = locales;

i18n.localeFallbacks = localeFallbacks;

/**
 * Use this method over `changeLanguage` to allow for fallback locales.
 * @param {String} locale
 */
i18n.changeLocale = (locale) => {
  const newLocale =
    locales.find((loc) => loc.value.toLowerCase() === locale?.toLowerCase())?.value ??
    i18n.localeFallbacks[locale]?.[0] ??
    i18n.localeFallbacks.default[0];
  if (newLocale && newLocale !== i18n.language) {
    i18n.changeLanguage(newLocale);
  }
};

window.i18n = i18n;

export default i18n;
