import environments from './environments';

const currentEnvironment = environments[window.location.hostname];

export const ANALYTICS_URL = currentEnvironment.analyticsUrl;
export const API_BASE_URL = currentEnvironment.apiBasePath;
const API_BASE_VERSION = 'api/v2';
export const API_BASE_PATH = `${currentEnvironment.apiBasePath}/${API_BASE_VERSION}`;
export const ASICS_ID_BASE_URL = currentEnvironment.asicsIDBaseUrl;
export const CLIENT_ID = currentEnvironment.clientId || 'oneasics_instore';
export const ENVIRONMENT_NAME = currentEnvironment.name;
export const ONETRUST_DATA_DOMAIN_SCRIPT = currentEnvironment.oneTrustDataDomainScript;
export const REDIRECT_URI = encodeURIComponent(currentEnvironment.redirectURI);
export const TEAMWORK_POLLING_MAX_TRIES = currentEnvironment.teamworkPollingMaxTries || 5;
export const TEAMWORK_POLLING_DELAY_MILLISECONDS = currentEnvironment.teamworkPollingDelayMilliseconds || 2000;
