import { css, Link, styled, useTheme } from '@asicsdigital/oneasics-blocks';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AccountIcon from '../../images/icon-account.svg';
import BenefitsIcon from '../../images/icon-benefits.svg';
import ScanIcon from '../../images/icon-scan.svg';
import ShopIcon from '../../images/icon-shop.svg';

const StyledNavBar = styled.div(({ theme }) => ({
  background: theme.colors.white,
  borderTop: `1px solid ${theme.colors.grey[300]}`,
  bottom: '0',
  display: 'flex',
  height: '50px',
  paddingBottom: '5px',
  paddingTop: '5px',
  position: 'sticky',
  width: '100vw',
  zIndex: 100,

  a: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0',
    width: '25%',
    color: theme.colors.textHintDark,

    div: {
      fontSize: theme.fontSizes.xSmall,
    },
  },
}));

const Icon = styled.img({
  height: '28px',
  marginBottom: '2px',
});

const ScanIconWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '28px',
  justifyContent: 'flex-end',
  marginBottom: '5px',

  img: {
    height: '18px',
    width: '100%',
  },
});

const AccountIconWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '34px',
  justifyContent: 'center',

  img: {
    height: '27px',
    width: '100%',
  },
});

/**
 * A bottom tiled navigation menu that is displayed when the user is logged in.
 */
const NavBar = () => {
  const { store } = useSelector((state) => state.router);
  const { t } = useTranslation();
  const theme = useTheme();

  const activeIconStyle = css({
    div: {
      // Doing this instead of using fontWeight: $semiBold so that the text doesn't shift the div due to the
      // new font size rendered.
      WebkitTextStrokeWidth: '0.75px',
      WebkitTextStrokeColor: theme.colors.primary,
    },

    img: {
      // Dynamically changing svg colors
      // ty https://codepen.io/sosuke/pen/Pjoqqp - matching hex $primary - #485cc2
      filter: 'invert(44%) sepia(28%) saturate(7455%) hue-rotate(218deg) brightness(80%) contrast(92%)',
    },
  });

  return (
    <StyledNavBar>
      <Link
        activeClassName={activeIconStyle}
        id="menu-member-id"
        linkType="nav"
        name="menu-member-id"
        path="/member-id"
        textDecoration="none"
      >
        <ScanIconWrapper>
          <Icon alt="Scan Icon" src={ScanIcon} />
        </ScanIconWrapper>
        <div>{t(`${store.regionId}:nav_bar.scan`)}</div>
      </Link>
      <Link
        activeClassName={activeIconStyle}
        id="menu-member-benefits"
        linkType="nav"
        name="menu-member-benefits"
        path="/member-benefits"
        textDecoration="none"
      >
        <Icon alt="Benefits Icon" src={BenefitsIcon} />
        <div>{t(`${store.regionId}:nav_bar.benefits`)}</div>
      </Link>
      <Link
        activeClassName={activeIconStyle}
        id="menu-member-shop"
        linkType="nav"
        name="menu-member-shop"
        path="/shop"
        textDecoration="none"
      >
        <Icon alt="Shop Icon" src={ShopIcon} />
        <div>{t(`${store.regionId}:nav_bar.shop`)}</div>
      </Link>
      <Link
        activeClassName={activeIconStyle}
        id="menu-member-account"
        linkType="nav"
        name="menu-member-account"
        path="/account"
        textDecoration="none"
      >
        <AccountIconWrapper>
          <Icon alt="Account Icon" src={AccountIcon} />
        </AccountIconWrapper>
        <div>{t(`${store.regionId}:nav_bar.account`)}</div>
      </Link>
    </StyledNavBar>
  );
};

NavBar.propTypes = {};

NavBar.defaultProps = {};

export default NavBar;
