import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API_BASE_PATH, CLIENT_ID } from '../utilities/constants';

export const getAccessToken = createAsyncThunk('getAccessToken', (params) =>
  fetch(
    `${API_BASE_PATH}/token`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `code=${params.code}`
        + `&code_verifier=${params.codeVerifier}`
        + '&grant_type=authorization_code'
        + `&client_id=${CLIENT_ID}`
        + `&redirect_uri=${params.redirectUri}`,
    },
  ).then((response) => response.json()),
);


const initialState = {
  accessToken: '',
  balance: 0,
  barcode: '',
  delayedBalances: [],
  error: '',
  isLoggedIn: false,
  user: {},
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    debugPoints: (state) => {
      state.balance = 10000;
      state.delayedBalances = [
        { balanceAmount: 120, balanceActivateDate: '2020-07-28T20:43:47Z' },
        { balanceAmount: 999, balanceActivateDate: '2020-07-28T20:43:47Z' },
        { balanceAmount: 500, balanceActivateDate: '2020-07-28T20:43:47Z' },
        { balanceAmount: 123, balanceActivateDate: '2020-07-28T20:43:47Z' },
        { balanceAmount: 1230, balanceActivateDate: '2020-12-28T20:43:47Z' },
      ];
     },
    resetUserData: () => initialState,
    setIsLoggedIn: (state) => {
      state.isLoggedIn = true;
    },
    setUserData: (state, action) => {
      state.balance = action.payload?.balance;
      state.barcode = (action.payload?.barcode || action.payload?.clutchId || action.payload?.membershipCode)?.toUpperCase();
      state.delayedBalances = action.payload?.delayedBalances;
      state.error = action.payload?.error;
      state.user = action.payload?.user;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAccessToken.fulfilled, (state, action) => {
      state.accessToken = action.payload.access_token;
    });
    builder.addCase(getAccessToken.rejected, (state) => {
      state.accessToken = '';
    });
  },
});

export const {
  debugPoints,
  resetUserData,
  setIsLoggedIn,
  setUserData,
} = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectCount = state => state.counter.value;

export default userSlice.reducer;
