// happy easter from josh lipinski
const easter = () => {
  let keylogger = '';

  const easter_words = { // tie key sequence to action
    ohwada4e: () => {
      const easter_block = document.querySelector('.background-overlay');
      easter_block.style.display = 'block';
      let timer;

      // remove if already implemented
      if (easter_block.classList.contains('easter')) {
        clearInterval(timer);
        easter_block.classList.remove('easter');
        easter_block.innerHTML = '';
        easter_block.style.display = 'none';
      } else { // implement
        const animate_count = (selector, start, end, duration) => { // animate a counter
          const range = end - start;
          let current = start;
          const increment = end > start ? 1 : -1;
          const stepTime = Math.abs(Math.floor(duration / range));
          const elem = document.querySelector(selector);
          timer = setInterval(() => {
            current += increment;
            elem.innerHTML = current;
            if (current === end) {
              clearInterval(timer);
            }
          }, stepTime);
        };

        easter_block.classList.add('easter'); // add easter class...
        const counter = document.createElement('div'); // ...add counter div
        counter.classList.add('easter_counter');
        easter_block.appendChild(counter);
        animate_count('.easter_counter', 0, 100000, 10000);
      }
    },
  };

  // add listeners for key patterns above
  Object.keys(easter_words).forEach((v) => {
    document.addEventListener('keypress', (e) => {
      keylogger = (keylogger + String.fromCharCode(e.keyCode || e.which)).substr(-(v.length));
      if (keylogger === v) {
        keylogger = '';
        easter_words[v].call(); // perform action tied to this sequence
      }
    });
  });
};

const eggs = easter();

export default eggs;
